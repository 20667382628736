<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fbagus-hernawan-A6JxK37IlPo-unsplash.jpg?alt=media&token=18ab0e24-5aef-4163-ad51-6096f98d009a"
      img-height="180"
    >
      <div class="title_">
        ログアウトの方法
      </div>
      <div class="explain">
        １．ログイン画面の右上にある自分のプロフィール画像を押してください。
      </div>
      <div class="explain">
        ２．ドロップダウンメニューのログアウトを押してください。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
