<template>
  <div class="">
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-primary"
        class="mr-1"
      >
        <feather-icon
          icon="FileTextIcon"
          size="20"
        />
      </b-avatar>
      <div>
        <h4>求人情報から探す</h4>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col lg="6">
        <JobTutorial />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAvatar, BRow, BCol } from 'bootstrap-vue'
import JobTutorial from '@/components/basic/tutorial/job/JobTutorial.vue'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    JobTutorial,
  },
  props: {
  },
}
</script>
