<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Farnel-hasanovic-MNd-Rka1o0Q-unsplash.jpg?alt=media&token=34fed839-2f9d-4cf6-b7bc-7156806e735f"
      img-height="180"
    >
      <div class="title_">
        新規登録の方法
      </div>
      <div class="explain">
        <b>skillismは、デスクトップパソコン、ノートパソコン、タブレット、モバイル（スマートフォン）から新規登録が可能です。オンライン環境下であれば、いつでも、どこでも、世界中で利用が可能です。</b>
      </div>
      <div class="explain">
        １．skillismのホームページ画面の右上にある「新規登録」を押してください。
      </div>
      <div class="explain">
        ２．メールアドレスの有効性を確認するため、メールアドレスを入力して「送信する」ボタンを押してください。
      </div>
      <div class="explain">
        ３．登録されたメールアドレスに「確認番号」が届くので、その番号を入力して、「確認する」ボタンを押してください。
      </div>
      <div class="explain">
        ４．続いて、下記の登録を進め、最後に「確認する」ボタンを押してください。
      </div>
      <div class="explain">
        <ul>
          <li>パスワード登録：登録条件は、8文字以上、利用できる文字は、英字大文字、英字小文字、数字、記号となります。</li>
          <li>パスワード登録（確認用）：「パスワード登録」と同じ内容を入力してください。</li>
          <li>所属カテゴリ：「受講者・求職者」「講師」のうち、登録したい所属カテゴリを選んでください。</li>
          <li>ニックネーム：アルファベットで登録してください。</li>
          <li>居住国：現在、住んでいる国を選んでください。</li>
          <li>タイムゾーン：現在、住んでいる国や地域で定められている標準時間帯(タイムゾーン)を選んでください。</li>
          <li>表示言語：希望の表示言語を選んでください。</li>
          <li>未成年確認：「成人である」「未成年である（親権者の同意を得ている）」のどちらかを選択してください。</li>
        </ul>
      </div>
      <div class="explain">
        ５．登録した内容、所属カテゴリにあった利用規約を確認していただき、利用規約への同意にチェックを入れ、
      </div>
      <div class="explain">
        「登録する」ボタンを押してください。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
