<template>
  <div class="">
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-primary"
        class="mr-1"
      >
        <feather-icon
          icon="FileTextIcon"
          size="20"
        />
      </b-avatar>
      <div>
        <h4>新規登録・ログイン・ログアウト</h4>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col lg="6">
        <AccountTutorial />
      </b-col>
      <b-col lg="6">
        <AccountTutorial2 />
        <AccountTutorial3 />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAvatar, BRow, BCol } from 'bootstrap-vue'
import AccountTutorial from '@/components/basic/tutorial/account/AccountTutorial.vue'
import AccountTutorial2 from '@/components/basic/tutorial/account/AccountTutorial2.vue'
import AccountTutorial3 from '@/components/basic/tutorial/account/AccountTutorial3.vue'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    AccountTutorial,
    AccountTutorial2,
    AccountTutorial3,
  },
  props: {
  },
}
</script>
