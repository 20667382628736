<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Flaura-davidson-QBAH4IldaZY-unsplash.jpg?alt=media&token=1f28ec58-e7ba-4148-825b-001bc922fa5f"
      img-height="180"
    >
      <div class="title_">
        オンラインサービスの申込から評価までの流れ
      </div>
      <div class="explain">
        １．学習サービス詳細ページにある下段にあるカレンダー内の「時間ボタン」を押してください。
      </div>
      <div class="explain">
        ２．申し込みたい希望時間を選択して、「次へ」ボタンを押してください。
      </div>
      <div class="explain">
        ３．希望するサービスの確認画面、通信手段、キャンセルポリシーを確認の上、「この内容で申し込む」ボタンを押してください。
      </div>
      <div class="explain">
        ４．必要コインに不足がなければ、申込が「登録完了」します。
      </div>
      <div class="explain">
        ５．「学習履歴一覧」「スケジュール」に申込内容が反映します。
      </div>
      <div class="explain">
        ６．予約したサービスの前日には、メールまたはメッセージでリマインド通知が届きます。（通知のオン/オフも可能）
      </div>
      <div class="explain">
        ７．サービスの通信手段と接続方法を確認して、サービスを受講してください。
      </div>
      <div class="explain">
        ８．サービスの終了後は、その講師へ評価をしてください。
      </div>
      <div class="explain">
        ９．後日、講師からフィードバックがありますので、確認してください。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
