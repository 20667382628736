<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fjess-bailey-q10VITrVYUM-unsplash2.jpg?alt=media&token=f88ac5f1-c854-41d6-a7d6-2e8982b9c532')`}"
      >
        <b-card-body class="text-center">
          <h2 class="title_">
            skillismご利用ガイド
          </h2>
          <b-card-text class="mb-2">
            skillismの使い方についてご説明します。
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        pills
        content-class="col-12 col-md-8 col-lg-9"
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12 "
        nav-class="nav-left"
      >
        <b-tab>
          <template #title>
            <feather-icon
              icon="PenToolIcon"
              size="18"
              class="mr-1"
            />
            学習から探す
          </template>
          <Learing />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="mr-1"
            />
            求人情報から探す
          </template>
          <Job />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="UploadCloudIcon"
              size="18"
              class="mr-1"
            />
            サービス登録
          </template>
          <Registration />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="CheckCircleIcon"
              size="18"
              class="mr-1"
            />
            新規登録・ログイン・ログアウト
          </template>
          <Account />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="RefreshCwIcon"
              size="18"
              class="mr-1"
            />
            パスワードの再設定・変更
          </template>
          <Setting />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="PowerIcon"
              size="18"
              class="mr-1"
            />
            推奨環境
          </template>
          <Environment />
        </b-tab>

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BTabs, BTab, BImg,
} from 'bootstrap-vue'
import Learing from '@/components/basic/tutorial/components/Learing.vue'
import Job from '@/components/basic/tutorial/components/Job.vue'
import Registration from '@/components/basic/tutorial/components/Registration.vue'
import Account from '@/components/basic/tutorial/components/Account.vue'
import Setting from '@/components/basic/tutorial/components/Setting.vue'
import Environment from '@/components/basic/tutorial/components/Environment.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BTabs,
    BTab,
    BImg,
    Learing,
    Job,
    Registration,
    Account,
    Setting,
    Environment,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    fetchData() {
      this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => { this.faqData = res.data })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>

<style scoped>
.title_ {
  font-size: 2.1rem;
  font-weight: 600
}
</style>
