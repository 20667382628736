<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fthought-catalog-UK78i6vK3sc-unsplash.jpg?alt=media&token=4ce647f2-c764-4f6b-acf3-61bdc993b238"
      img-height="180"
    >
      <div class="title_">
        パスワードの再設定
      </div>
      <div class="explain">
        １．skillismのホームページ画面の右上にある「ログイン」を押してください。
      </div>
      <div class="explain">
        ２．「パスワードを忘れた場合」を押してください。
      </div>
      <div class="explain">
        ３．登録時に利用したメールアドレスを入力してください。
      </div>
      <div class="explain">
        ４．次に「パスワードを再設定する」を押してください。
      </div>
      <div class="explain">
        ５．受信ボックスにパスワード再設定用メールが届いたら、パスワード再設定用URLからパスワード変更をしてください。
      </div>
      <div class="explain">
        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body mt-1 mb-2">
            <div class="mb-1">
              <b>※ご注意</b>
            </div>
            <div>
              １時間以内にパスワードリセットメッセージが届かない場合は、迷惑メールフォルダーをご確認ください。また、info@skillism.comのメールが安全な送信者のリストに追加されていることを確認してください。
            </div>
          </div>
        </b-alert>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BAlert } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAlert,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
