<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fj-kelly-brito-fI6PXU5-kKU-unsplash.jpg?alt=media&token=9aae4283-27a9-40dd-a664-9fa20972fbfa"
      img-height="180"
    >
      <div class="title_">
        ログインの方法
      </div>
      <div class="explain">
        １．skillismのホームページ画面の右上にある「ログイン」を押してください。
      </div>
      <div class="explain">
        ２．メールアドレスとパスワードを入力して「ログイン」ボタンを押してください。
      </div>
      <div class="explain">
        ※最後に利用していた所属カテゴリのダッシュボードが表示されます。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
