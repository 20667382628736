<template>
  <div class="">
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-primary"
        class="mr-1"
      >
        <feather-icon
          icon="UploadCloudIcon"
          size="20"
        />
      </b-avatar>
      <div>
        <h4>サービス登録</h4>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col lg="6">
        <RegistrationService />
      </b-col>
      <b-col lg="6">
        <RegistrationService2 />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAvatar, BRow, BCol } from 'bootstrap-vue'
import RegistrationService from '@/components/basic/tutorial/registration/RegistrationService.vue'
import RegistrationService2 from '@/components/basic/tutorial/registration/RegistrationService2.vue'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    RegistrationService,
    RegistrationService2,
  },
  props: {
  },
}
</script>
