<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Ftyler-franta-iusJ25iYu1c-unsplash.jpg?alt=media&token=29527066-b8e9-492b-91d1-2b84d6f486a9"
      img-height="180"
    >
      <div class="title_">
        サービスの検索・詳細確認の方法
      </div>
      <div class="explain">
        １．ログインをして、上段の「求人情報から探す」を押してください。
      </div>
      <div class="explain">
        ２．希望の求人情報を探す方法として「カテゴリ」「勤務地」を入力、選択し、「検索する」ボタンを押してください。
      </div>
      <div class="explain">
        ３．検索結果は下段に表示されます。希望の求人情報の詳細、応募は求人情報タイトルを押してください。
      </div>
      <div class="explain">
        ４．求人情報の詳細では、求人情報の内容、応募条件等が表示されます。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
