<template>
  <b-row class="match-height">
    <b-col lg="12">
      <Tutorial class="mb-3" />
      <Footer />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Tutorial from '@/components/basic/tutorial/Tutorial.vue'
import Footer from '@/components/basic/footer/Footer.vue'

export default {
  components: {
    BRow,
    BCol,
    Tutorial,
    Footer,
  },
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>

<style scoped>
</style>
