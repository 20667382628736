<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fjeshoots-com-pUAM5hPaCRI-unsplash.jpg?alt=media&token=ab8515e4-c77e-419a-a291-f8b5267bc0dd"
      img-height="180"
    >
      <div class="title_">
        サービスの検索・詳細確認の方法
      </div>
      <div class="explain">
        １．ログインをして、上段の「学習から探す」を押してください。
      </div>
      <div class="explain">
        ２．希望の学習サービスを探す方法として「キーワード検索 or カテゴリ検索」「学ぶ方法」を入力、選択し、「検索する」ボタンを押してください。
      </div>
      <div class="explain">
        ３．検索結果は下段に表示されます。希望の学習サービスの詳細、申込はサービスタイトルを押してください。
      </div>
      <div class="explain">
        ４．学習サービスの詳細では、講師の概要、学習サービスの内容、必要なコイン、時間、対象レベル等が表示されます。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
