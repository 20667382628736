<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Favel-chuklanov-DUmFLtMeAbQ-unsplash.jpg?alt=media&token=18a750cf-72d8-460e-84f6-14fa050d27ff"
      img-height="180"
    >
      <div class="title_">
        通常のサービス登録の方法
      </div>
      <div class="explain">
        １．ログインをして「サービス登録」を押してください。
      </div>
      <div class="explain">
        ２．サービスの新規登録の「新規登録」ボタンを押してください。
      </div>
      <div class="explain">
        ３．続いて、下記の登録を進め、最後に「確認する」ボタンを押してください。
      </div>
      <div class="explain">
        <ul>
          <li>公開条件：公開、リクエスト、非公開から選択してください。</li>
          <li>サービス方法：オンライン、オフラインなどの５種から選択してください。</li>
          <li>サービスタイトル：受講者が検索時に表示されるサービスのタイトル名を決めてください。</li>
          <li>サービス時間：サービスの実施時間を決めてください。</li>
          <li>必要コイン：サービスの対価として得るコイン数を決めてください。</li>
          <li>※但し、精算の際に手数料として20〜25％の手数料が発生します。</li>
          <li>サービス内容・説明：サービスの内容や具体的に紹介してください。</li>
          <li>検索用カテゴリ：サービスのカテゴリを選択してください。</li>
          <li>検索用キーワード：カテゴリ以外で強調したいキーワードを登録してください。</li>
          <li>サムネイル画像：サムネイル画像をアップロードするか、規定の中から選んでください。</li>
          <li>対応レベル：サービスの受講レベルを選んでください。</li>
          <li>利用通信手段：skypeまたはZOOMを選んでください。</li>
          <li>定員人数：一度のサービスで参加できる受講者の定員を選んでください。</li>
          <li>最少催行人数：一度のサービスで実施する受講者の最少人数を選んでください。</li>
          <li>最終受付時間：サービスの最終受付時間を選んでください。</li>
          <li>キャンセルポリシー：キャンセルポリシーを「当社規定」「オリジナル」で選んでください。</li>
          <li>資格保有の有無：提供するサービスに対して、資格を保有しているかを選んでください。</li>
        </ul>
      </div>
      <div class="explain">
        ４．続いて、サービスの可能日時の登録をしてください。
      </div>
      <div class="explain">
        <b class="ml-1">単独登録</b><br>
        <ol>
          <li>サービスの提供する日付を押してください。</li>
          <li>提供する時間を選んで「セット」ボタンを押してください。</li>
        </ol>
      </div>
      <div class="explain">
        <b class="ml-1">一括登録</b><br>
        <ol>
          <li>サービスの提供する日付を押してください。</li>
          <li>「一括登録」を選んでください。</li>
          <li>1行目の提供する時間を選んでください。</li>
          <li>「追加」ボタンを押してください。</li>
          <li>2行目の提供する日付と時間を選んでください。</li>
          <li>4と5の繰り返し</li>
          <li>「セット」ボタンを押してください。</li>
        </ol>
      </div>
      <div class="explain">
        <b class="ml-1">繰り返し登録</b><br>
        <ol>
          <li>サービスの提供する日付を押してください。</li>
          <li>「繰り返し登録」を選んでください。</li>
          <li>1行目の提供する時間を選んでください。</li>
          <li>繰り返しで「日、週、月（曜日）」の3つから選んでください。</li>
          <li>該当する日、曜日、週指定を選んでください。</li>
          <li>繰り返しの終了日を選んでください。</li>
          <li>「セット」ボタンを押してください。</li>
        </ol>
      </div>
      <div class="explain">
        ５．カレンダーに登録された情報が反映しているかを確認してください。
      </div>
      <div class="explain">
        ６．ホームページの「学習から探す」でも登録した情報が掲載されているかを確認してください。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
