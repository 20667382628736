<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fcorinne-kutz-tMI2_-r5Nfo-unsplash.jpg?alt=media&token=6ccc9896-c943-4d07-ba94-53b296d78910"
      img-height="180"
    >
      <div class="title_">
        パスワードを変更する
      </div>
      <div class="explain">
        パスワードを変更したい場合は、ログイン後に基本設定からいつでも変更ができます。
      </div>
      <div class="explain">
        １．ダッシュボード内の基本設定を押してください。
      </div>
      <div class="explain">
        ２．基本設定内のパスワードのデータを消して、新しいパスワードを入力してください。
      </div>
      <div class="explain">
        ３．「登録する」ボタンを押し、変更することを承認してください。
      </div>
      <div class="explain">
        ４．パスワードの変更が完了します。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
