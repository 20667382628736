<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fkimberly-farmer-lUaaKCUANVI-unsplash.jpg?alt=media&token=b00963db-d2d0-4eba-832a-00c71a5d1bc9"
      img-height="180"
    >
      <div class="title_">
        オフラインサービスの申込方法
      </div>
      <div class="explain">
        １．サービス詳細ページの下段または右側にある「申込する」ボタンを押してください。
      </div>
      <div class="explain">
        ２．希望するサービスの確認画面、キャンセルポリシーを確認の上、「この内容で申し込む」ボタンを押してください。
      </div>
      <div class="explain">
        ３．必要コインに不足がなければ、申込が「登録完了」します。
      </div>
      <div class="explain">
        ４．「学習履歴一覧」に申込内容が反映します。
      </div>
      <div class="explain">
        ５．一度、申込いただきましたオフラインサービスは、いつでも確認することができます。
      </div>
      <div class="explain">
        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body mt-1 mb-2">
            講師のサービス実施終了またはyoutubeのアカウントの停止などに伴い、事前の告知することなしでサービスが利用できないくなることがあります。
          </div>
        </b-alert>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BAlert } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAlert,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
