<template>
  <div class="">
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-primary"
        class="mr-1"
      >
        <feather-icon
          icon="RefreshCwIcon"
          size="20"
        />
      </b-avatar>
      <div>
        <h4>パスワードの再設定・変更</h4>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col lg="6">
        <SettingTutorial />
      </b-col>
      <b-col lg="6">
        <SettingTutorial2 />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAvatar, BRow, BCol } from 'bootstrap-vue'
import SettingTutorial from '@/components/basic/tutorial/setting/SettingTutorial.vue'
import SettingTutorial2 from '@/components/basic/tutorial/setting/SettingTutorial2.vue'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    SettingTutorial,
    SettingTutorial2,
  },
  props: {
  },
}
</script>
