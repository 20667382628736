<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fmanuel-cosentino-n--CMLApjfI-unsplash.jpg?alt=media&token=73b6c72f-4354-40fa-9bde-58bf6014712f"
      img-height="180"
    >
      <div class="title_">
        【日本語講師限定】日本語グループ会話レッスンの登録
      </div>
      <div class="explain">
        １．サービスの新規登録の「日本語グループ会話レッスンの登録」ボタンを押してください。
      </div>
      <div class="explain">
        ２．サービスの新規登録の「新規登録」ボタンを押してください。
      </div>
      <div class="explain">
        ３．続いて、下記の登録を進め、最後に「確認する」ボタンを押してください。
      </div>
      <div class="explain">
        <ul>
          <li>公開条件：公開、リクエスト、非公開から選択してください。</li>
          <li>サービス方法：オンライン、オフラインなどの５種から選択してください。</li>
          <li>会話テーマ：実施する会話テーマを選んでください。</li>
          <li>サービス時間：本サービスの時間は５５分と固定となります。</li>
          <li>獲得コイン：参加者は１～４名までで、５５分/１,５００円と固定となります。</li>
          <li>サービス内容・説明：サービスの内容や具体的に紹介してください。</li>
          <li>対応レベル：サービスの受講レベルを選んでください。</li>
          <li>利用通信手段：skypeまたはZOOMを選んでください。</li>
          <li>定員人数：定員は最大４名で固定となります。</li>
          <li>最少催行人数：最少催行人数は１名となります。</li>
          <li>最終受付時間：サービスの最終受付時間を選んでください。</li>
          <li>キャンセルポリシー：キャンセルポリシーは「当社規定」で固定となります。</li>
          <li>資格保有の有無：提供するサービスに対して、資格を保有しているかを選んでください。</li>
        </ul>
      </div>
      <div class="explain">
        ４．続いて、サービスの可能日時の登録をしてください。
      </div>
      <div class="explain">
        <b class="ml-1">単独登録</b><br>
        <ol>
          <li>サービスの提供する日付を押してください。</li>
          <li>提供する時間を選んで「セット」ボタンを押してください。</li>
        </ol>
      </div>
      <div class="explain">
        <b class="ml-1">一括登録</b><br>
        <ol>
          <li>サービスの提供する日付を押してください。</li>
          <li>「一括登録」を選んでください。</li>
          <li>1行目の提供する時間を選んでください。</li>
          <li>「追加」ボタンを押してください。</li>
          <li>2行目の提供する日付と時間を選んでください。</li>
          <li>4と5の繰り返し</li>
          <li>「セット」ボタンを押してください。</li>
        </ol>
      </div>
      <div class="explain">
        <b class="ml-1">繰り返し登録</b><br>
        <ol>
          <li>サービスの提供する日付を押してください。</li>
          <li>「繰り返し登録」を選んでください。</li>
          <li>1行目の提供する時間を選んでください。</li>
          <li>繰り返しで「日、週、月（曜日）」の3つから選んでください。</li>
          <li>該当する日、曜日、週指定を選んでください。</li>
          <li>繰り返しの終了日を選んでください。</li>
          <li>「セット」ボタンを押してください。</li>
        </ol>
      </div>
      <div class="explain">
        ５．カレンダーに登録された情報が反映しているかを確認してください。
      </div>
      <div class="explain">
        ６．ホームページの「学習から探す」でも登録した情報が掲載されているかを確認してください。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
