<template>
  <div class="">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fchristopher-gower-m_HRfLhgABo-unsplash.jpg?alt=media&token=96aa92a2-56f2-40b5-b47d-ee18cb33d3b0"
      img-height="180"
    >
      <div class="explain">
        <b>skillismは、デスクトップパソコン、ノートパソコン、タブレット、モバイル（スマートフォン）から利用が可能です。オンライン環境下であれば、いつでも、どこでも、世界中で利用が可能です。</b>
      </div>
      <div class="title_">
        利用環境
      </div>
      <div class="explain">
        デスクトップ/ノートパソコン用の最新のChrome、Firefox、Safari、Edge、Opera
      </div>
      <div class="explain">
        5 Mbitまたは800 kbps以上のブロードバンド接続
      </div>
      <div class="explain">
        通信接続用ソフト（skype、ZOOM）のダウンロードおよび接続
      </div>
      <div class="title_">
        windows
      </div>
      <div class="explain">
        プラットフォーム: Windows 8.1以降（最新の更新プログラムをインストール済み）
      </div>
      <div class="explain">
        プロセッサー: 1.8 GHz以上のCPU
      </div>
      <div class="explain">
        RAM: 4GB以上
      </div>
      <div class="explain">
        ビデオ: グラフィック出力機能
      </div>
      <div class="explain">
        サウンド: サウンド出力機能
      </div>
      <div class="title_">
        Mac
      </div>
      <div class="explain">
        プラットフォーム: Mac OS X 10.12以上（最新まで更新をインストール済み）
      </div>
      <div class="explain">
        プロセッサー: 1.8 GHz以上のCPU
      </div>
      <div class="explain">
        RAM: 4GB以上
      </div>
      <div class="explain">
        ビデオ: グラフィック出力機能
      </div>
      <div class="explain">
        サウンド: サウンド出力機能
      </div>
      <div class="title_">
        モバイル（スマートフォン）
      </div>
      <div class="explain">
        iOS 13.0以上
      </div>
      <div class="explain">
        Android 6.0以上
      </div>
      <div class="explain">
        モバイルブラウザ: Safari、Chrome（iOS）、Chrome（Android）
      </div>
      <div class="explain">
        ※タブレットやモバイル（スマートフォン）でのサービスの受講に関する注意事項<br>
        3G接続や移動中での受講は、安定した接続ができない可能性があるため、Wi-Fiでの接続および移動しない環境での受講をおすすめします。
      </div>
      <div class="explain">
        手持ちでの受講は画面のブレなどで講師側に映像が見えづらくなってしまうほか、落とす危険もあるため、受講の際は安定する箇所に設置して受講することをおすすめします。
      </div>
      <div class="explain">
        skypeやZOOMでの講師によるオンラインレッスン、もしくは求人企業との面接においては、音声の乱れの抑止や周りの音による騒音の対策として、マイク付きイヤホン、ヘッドセットの利用をおすすめします。
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 3% 0;
}
.explain {
  font-size: 1rem;
  margin: 0 0 3% 0;
}
</style>
